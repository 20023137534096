.main-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 42px 30px 20px;
}

.main-header-heading-overview {
  font-weight: 500;
  flex: 1;
  font-family: "Montserrat", sans-serif;
  font-size: 36px;
}

.header-custom-select .ant-select-selector {
  width: 248px !important;
  height: 40px !important;
  border-radius: 8px !important;
  background-color: #fff !important;
  padding-top: 8px !important;
  font-family: "Poppins" !important;
  color: #110f0e;
  font-size: 13px;
}

.header-custom-select .ant-select-arrow ::before {
  color: #110f0e;
  background-color: #fafafa !important;
  border-top: #fafafa;
}
.ant-tooltip-placement-top .ant-tooltip-arrow-content {
  background-color: #fafafa !important;
}
.ant-tooltip-arrow-content {
  background-color: #fafafa !important;
}
.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  padding: 0;
  line-height: 23px !important;
}

.add-owner-form .header-custom-input {
  width: 90%;
  height: 45px;
  background: #fff;
  color: #110f0e;
  border-radius: 8px;
  font-size: 13px;
}

.header-custom-select-container {
  width: 90%;
}
.add-owner-form .header-btn {
  margin-top: 15px;
  width: 90%;
  color: #212121 !important;
  border-radius: 4px;
  background-color: #ffd936;
  height: 40px;
}
.add-owner-form .header-btn:hover {
  color: #212121;
  background-color: #ffd936cc;
}
.add-owner-form .header-custom-select .ant-select-selector {
  width: 100% !important;
  height: 40px !important;
  /* lineheight: 24px; */
  border-radius: 8px !important;
  background-color: #fafafa !important;
  padding-top: 8px !important;
  color: #110f0e;
  font-size: 13px;
}
.card-wrapper {
  background: #fafafa;
  border-radius: 8px;
  border: 1px solid #e7e7e7;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 12px;
  padding-left: 20px !important;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between; /* Ensure the elements are spread out evenly */
}

.main-header-content {
  display: flex;
  align-items: center;
}

.main-header {
  margin-left: 10px; /* Adjust this value for spacing */
  font-size: 32px; /* Adjust this value to decrease the font size */
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
}

.yearly-overview-image {
  width: 25px; /* Adjust size as needed */
  height: 25px; /* Adjust size as needed */
  margin-right: 10px; /* Add space between image and text */
}

.address-container {
  display: flex;
  align-items: center;
}

.content-table-container .ant-table-tbody > tr > td,
.content-table-container .ant-table-thead > tr > th {
  font-family: "Montserrat", sans-serif !important;
}

.content-table-container .ant-table-thead > tr > .boldColumn,
.content-table-container .ant-table-tbody > tr > .boldColumn.ant-table-cell {
  font-family: "Montserrat", sans-serif !important;
  font-size: 13px;
  font-weight: medium;
  line-height: 19.5px;
  text-align: left;
}

.table-section-container {
  padding: 0px 42px 0px 20px !important;
}
.recap-table-heading {
  margin: 24px 0px !important;
  font-family: "Montserrat", sans-serif !important;

  font-size: 24px;
  font-weight: 600;
  line-height: 24px;
}

.content-table-container {
  margin-top: 20px;
}
.content-table-container .ant-table {
  border: none;
}

.content-table-container .ant-table-thead > tr > th {
  background: #fafafa;
  font-weight: 500;
  /* font-family: "Poppins" !important; */
  border-bottom: 1px solid #e7e7e7;
  font-size: 13px;
  text-align: left;
}

.content-table-container .ant-table-tbody > tr:hover {
  cursor: pointer;
}

.content-table-container .ant-table-tbody > tr > td {
  background: transparent !important;
  border-bottom: 1px solid #e7e7e7;
  padding: 12px 16px !important;
  font-size: 13px;
  font-weight: 400;
  text-align: left;
}
.content-table-container .ant-table-tbody > tr > .boldColumn.ant-table-cell {
  font-weight: 600;
}

.content-table-container .ant-table-tbody > tr:hover > td {
  background-color: #fdfae7 !important;
}
.content-table-container .ant-table-tbody > tr:active > td {
  background-color: #fdfae7 !important;
}

.content-table-container .table-content-tick-icon {
  color: rgba(0, 0, 0, 0.85) !important;
}
.table-content-tick-icon {
  line-height: 0px !important;
}
.selected-row {
  background-color: #fdfae7 !important;
}
.empty-indicator {
  display: inline-block;
  width: 16px;
  text-align: center;
  font-weight: bold;
  font-size: 8px;
  line-height: 16px;
  color: black;
}
.selected-row {
  background-color: #fdfae7 !important;
}
.list-description {
  color: #6c757d; /* Slightly dark grey */
  font-size: 14px; /* Relative size, adjust as needed */
  margin-bottom: 16px; /* Space below the description */
  font-family: "Montserrat", sans-serif;
  margin-top: -10px;
}

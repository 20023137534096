.chart-section {
  padding: 0px 42px 100px 20px !important;
  height: 400px;
}
.chart-main-heading {
  margin: 24px 0px 0px 0px !important;
  font-size: 24px;
  line-height: 24px;
  font-weight: 600;
  font-family: "Montserrat", sans-serif !important;
}
.chart-sub-heading {
  margin: 0px 0px 24px 0px !important;
  font-size: 12px;
  line-height: 24px;
  font-weight: 600;
  font-family: "Montserrat", sans-serif !important;
}
.chart-wrapper {
  height: 180%;
  width: 100%;
  background-color: #fafafa;
  border-radius: 10px;
}

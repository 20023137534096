.summary-header {
  padding-right: 48px;
  padding-left: 25px;
}

.summary-heading-text {
  font-family: "Montserrat", sans-serif !important;
  font-weight: 600;
  font-size: 24px;
  margin-top: 40px !important;
  line-height: 24px;
  margin-bottom: 30px !important;
  display: flex;
  align-items: center;
}

.summary-heading-text .ant-badge {
  margin-left: 10px;
}

.tooltip-left-align {
  text-align: left !important;
}

.ant-tooltip .ant-tooltip-inner {
  color: rgb(0, 0, 0) !important;
  background-color: rgb(255, 255, 255) !important;
  font-size: 11px;
  font-weight: 500;
  font-style: italic;
  font-family: "Montserrat";
  text-align: center !important;
  padding: 10px 10px 20px 10px !important;
  border-radius: 8px !important;
}

.ant-tooltip .ant-tooltip-arrow-content::before {
  background: #fff;
}

.summary-first-row {
  margin: 0px 48px 0px 20px !important;
  display: flex;
  gap: 20px 0px !important;
  border-radius: 8px;
  background: #fafafa;
  border: 1px solid #e7e7e7;
}

.summary-single-card-container {
  padding: 20px 24px !important;
  background: #fafafa;
  border-width: 4px;
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 260px;
  flex-grow: 1;
  min-width: 0;
  transition: all 0.3s ease-in-out;
  border-radius: 8px;
}
.OtherRowsContainr {
  margin: 24px 48px 0px 20px !important;
  display: flex;

  flex-direction: row;
  gap: 20px;
}
.summary-single-card-value {
  font-size: 23px;
  font-family: "Montserrat";
  font-weight: 600;
  line-height: 23px;
  text-align: center;
  margin-top: 4px !important; /* Reduce this value to decrease space */
  line-height: 1.5 !important; /* Adjust line-height to fine-tune spacing */
}
.summary-single-card-title {
  font-size: 12px;
  line-height: 15px;
  font-weight: 600;
  color: #888888 !important;
  font-weight: bolder;
  font-family: "Montserrat" !important;
  text-align: center;
  margin: 0px 0px 20px 0px !important;
  text-transform: uppercase;
  margin-bottom: 2.2px !important; /* Reduce this value to decrease space */
  line-height: 1.2 !important; /* Adjust line-height to fine-tune spacing */
}

.summary-2x-row {
  display: flex;
  flex-direction: column;
  width: 50%;
  border-radius: 8px;
  border: 1px solid #e7e7e7;
  background: #fafafa;
  padding: 8px 0px;
}

.summary-2x-row-container {
  padding: 30px 0px !important;
  background: #fafafa00;
  display: flex;
  flex-grow: 1;
  width: 100%;
  transition: all 0.3s ease-in-out;
}

.summary-2x-row-content {
  padding: 0px 12px 0px 12px !important;
  flex-grow: 1;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.ant-tooltip .ant-tooltip-inner {
  color: rgb(0, 0, 0) !important;
  background-color: rgb(255, 255, 255) !important;
  font-size: 13px;
  font-weight: 500;
  font-style: italic;
  font-family: "Montserrat";
  text-align: center !important;
  padding: 10px 10px 20px 10px !important;
  border-radius: 8px !important;
}

.ant-tooltip .ant-tooltip-arrow-content::before {
  background: #fff;
}

.first-row-card-title {
  background: #ebedf1;
  color: rgb(0, 0, 0) !important;
  font-family: "Montserrat";
  font-size: 15px;
  line-height: 15px;
  font-weight: 600;
  text-align: center;
  padding: 4px 15px;
  border-radius: 24px;
  margin: 0px 0px 12px 0px !important;
  margin-bottom: 2.8px !important; /* Reduce this value to decrease space */
  line-height: 1.2 !important; /* Adjust line-height to fine-tune spacing */
}
.tooltip-left-align {
  text-align: left !important;
}

.custom-dropdown {
  position: relative;
  width: 100%;
}

.custom-dropdown__selected {
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  height: 40px;
  padding: 8px 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.custom-dropdown__arrow {
  margin-left: 10px;
  color: rgb(178, 179, 179);
  font-size: 13px;
}

.custom-dropdown__options {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: white;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  margin-top: 4px;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000;
}

.custom-dropdown__option {
  padding: 8px 12px;
  cursor: pointer;
}

.custom-dropdown__option:hover,
.custom-dropdown__option.selected {
  background-color: #fffce6;
}

.user-table .ant-table-tbody > tr:hover > td {
  background-color: #fff3c1 !important;
}

.edit-icon,
.filter-icon,
.delete-icon {
  color: black !important;
  background-color: #f8f8f8 !important;
  border-radius: 6px !important;
  padding: 8px !important;
  transition: background-color 0.3s, box-shadow 0.3s;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.edit-icon:hover,
.filter-icon:hover,
.delete-icon:hover,
.edit-icon:active,
.filter-icon:active,
.delete-icon:active {
  background-color: #ffd936 !important;
  color: white !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* General styling remains unchanged */

.edit-icon,
.delete-icon {
  color: black !important;
  background-color: #f8f8f8 !important;
  border-radius: 6px !important;
  padding: 8px !important;
  transition: background-color 0.3s, box-shadow 0.3s;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.edit-icon:hover,
.edit-icon:active {
  background-color: #ffd936 !important;
  color: white !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Separate rule for delete-icon to ensure it is applied */
.delete-icon:hover,
.delete-icon:active {
  background-color: #ff4d4f !important; /* Red color */
  color: white !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.filter-icon {
  color: black !important;
  background-color: #f8f8f8 !important;
  border-radius: 6px !important;
  padding: 8px !important;
  transition: background-color 0.3s, box-shadow 0.3s;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.filter-icon:hover,
.filter-icon:active {
  background-color: #ffd936 !important;
  color: white !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
.filter-icon {
  color: black !important;
  background-color: #f8f8f8 !important;
  border-radius: 6px !important;
  padding: 8px !important;
  transition: background-color 0.3s, box-shadow 0.3s;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.filter-icon:hover,
.filter-icon:active {
  background-color: #ffd936 !important;
  color: white !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

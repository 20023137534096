.user-info-container {
  padding: 0px 16px 0px 20px;
}

.user-info-user-name {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 2px !important; /* Reduced margin */
  line-height: 24px;
  font-weight: bold;
  color: #110f0e;
  font-family: "Poppins";
}

.yearly-user-info-user-name {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 2px !important; /* Reduced margin */
  line-height: 24px;
  font-family: "Poppins";
  margin-left: 100px;
}

.user-info-address,
.address-text {
  font-size: 13px;
  font-weight: 400;
  font-family: "Montserrat";
}

.address-container {
  display: flex;
  align-items: center; /* Center the items vertically */
  margin-left: 100px;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(250, 250, 250);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(191, 191, 191);
  border-radius: 5px;
}

#root {
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.ant-layout {
  height: 100vh;
  overflow: auto;
}

.ant-layout-sider {
  padding: 16px 16px 0px 16px;
  background: rgba(250, 250, 250) !important;
  height: 100vh;
  left: 0;
}
.logo {
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-image {
  max-width: 100%;
  height: auto;
  width: 130px;
  padding: 8px;
  padding-bottom: 124 !important;
  margin-bottom: 40px !important;
  box-sizing: border-box;
}

.custom-menu {
  background: transparent !important;
}

.sidebar-link .ant-menu-item {
  background-color: transparent !important;
  font-size: 13px !important;
  font-weight: 400 !important;
  font-family: "Poppins" !important;
  /* padding: 12px !important; */
  padding-top: 24px !important;
  padding-bottom: 26px !important;
  color: #110f0e !important;
  width: 100%;
}

.sidebar-link.active .ant-menu-item,
.sidebar-link:hover .ant-menu-item {
  background-color: #fff3c1 !important;
  color: #110f0e;
}

.profile-name {
  color: black;
  font-family: Montserrat !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  text-align: center;
  margin-top: 9px;
}
.profile-email {
  color: gray !important;
  font-family: Montserrat !important;
  font-size: 9px !important;
  font-weight: 700 !important;
  text-align: center;
  margin-top: 9px;
}

.bottom-menu-icon {
  cursor: pointer;
  padding: 10px;
  border-radius: 15%;
  /* margin-right: -5px; */
  margin-left: 10px;
  margin-bottom: 10px;
}

.bottom-menu-icon:hover {
  transition: all 0.3s ease;

  background-color: white;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
}

/* .profile-data-holder {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
} */

.bottom-menu-overlay {
  left: -200px !important;
  border-radius: 8px; /* Add border-radius for rounded corners */
  padding: 8px; /* Add padding for spacing */
}

.user-profile-img {
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile-text-holder {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.ant-dropdown-menu {
  background-color: white;
  border-radius: 8px !important;
  padding: 18px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  margin-bottom: 8px;
}

.ant-dropdown-menu-item {
  color: black !important;
  padding: 8px 12px !important;
  border-radius: 8px; /* Add border-radius for rounded corners */
  margin-bottom: 8px; /* Add margin-bottom for spacing */
}

.ant-dropdown-menu-item:hover {
  background-color: #fff3c1 !important;
}

.bottom-menu .ant-dropdown-menu {
  width: 220px !important;
  padding: 8px;
}

.bottom-menu .ant-dropdown-menu-item {
  background-color: white !important;
  color: black !important;
  border-radius: 8px;
  margin-bottom: 8px;
}

.bottom-menu .ant-dropdown-menu-item:hover {
  background-color: #fff3c1 !important;
}
.menu-item {
  display: flex;
  align-items: center;
}

.menu-item .ant-image {
  margin-right: 10px;
}

.menu-item .ant-menu-item-only-child {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
}
